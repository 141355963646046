import React, { ReactNode } from 'react';
import styled from 'styled-components';

/**
 * HOW TO USE
 *
 * @param variant - This is the default style of the typography. This matches with our type system
 * in the design system
 *
 * @param as - This is to be used to make sure we can continue to have the proper semantic html
 * tag references within our typography component
 *
 * @param color - This directly corresponds to the color css property. We should be passing in
 * a color from of design system css variables. Ex: color="var(--color-black-500)"
 *
 * @param laptop
 * @param tablet
 * @param phone  - These are used to pass in different type styles for our
 * different breakpoints. You can find the values of these breakpoints in theme.ts in the QUERIES
 * variable
 *
 * @param maxLines - This is used to clamp the typography to a certain number of lines. You can
 * pass in just a number, which will be used as the default line clamp. You can also pass in
 * different line clamps for different breakpoints by using the MaxLines interface
 *
 * @param textAlign - This directly corresponds to the text-align css property and should be used as such
 *
 * @param textTransform - This corresponds to the text-transform css property
 *
 * @param textDecoration - This correspond to the text-decoration css property
 *
 * @param wordBreak - This corresponds to the word-break css property
 *
 * WHEN TO USE THIS COMPONENT
 *
 * We should use this component when we have to display text in places where there will be no
 * side effects. Side effects would include anything that will change the styling of the text
 * outside of what already is available and defined. For example, if we have want to have
 * text with a hover event that will change the text's properties then we should create a
 * styled component specifically for that case where it is being used.
 *
 * HOW TO HANDLE PADDING & MARGIN
 *
 * As you can tell, there are no styles in this component that allow for giving padding or
 * margin to the Typography component. The approach to add spacing would be to wrap the
 * component in a div (or other block level HTML tag that is semantically correct) and add
 * the padding
 *
 * If you have a suggestion on how to include padding/margin in this component, let me know
 * and we can discuss.
 *
 * Blake C
 */

const BaseText = styled.div`
  font-family: 'HKGrotesk';
  font-size: var(--font-size);
  font-weight: var(--font-weight);
  letter-spacing: var(--letter-spacing);
  line-height: var(--line-height);
  color: var(--color);
  text-align: var(--text-align);
  text-transform: var(--text-transform);
  text-decoration: var(--text-decoration);
  overflow-wrap: break-word;
  white-space: var(--pre-wrap);
  word-break: var(--word-break);

  @media ${(p) => p.theme.queries.laptopAndDown} {
    font-size: var(--font-size-laptop, var(--font-size));
    font-weight: var(--font-weight-laptop, var(--font-weight));
    letter-spacing: var(--letter-spacing-laptop, var(--letter-spacing));
    line-height: var(--line-height-laptop, var(--line-height));
    text-align: var(--text-align-laptop, var(--text-align));
    color: var(--color-laptop, var(--color));
  }

  @media ${(p) => p.theme.queries.tabletAndDown} {
    font-size: var(
      --font-size-tablet,
      var(--font-size-laptop, var(--font-size))
    );
    font-weight: var(
      --font-weight-tablet,
      var(--font-weight-laptop, var(--font-weight))
    );
    letter-spacing: var(
      --letter-spacing-tablet,
      var(--letter-spacing-laptop, var(--letter-spacing))
    );
    line-height: var(
      --line-height-tablet,
      var(--line-height-laptop, var(--line-height))
    );
    text-align: var(
      --text-align-tablet,
      var(--text-align-laptop, var(--text-align))
    );
    color: var(--color-tablet, var(--color-laptop, var(--color)));
  }

  @media ${(p) => p.theme.queries.phoneAndDown} {
    font-size: var(
      --font-size-phone,
      var(--font-size-tablet, var(--font-size-laptop, var(--font-size)))
    );
    font-weight: var(
      --font-weight-phone,
      var(--font-weight-tablet, var(--font-weight-laptop, var(--font-weight)))
    );
    letter-spacing: var(
      --letter-spacing-phone,
      var(
        --letter-spacing-tablet,
        var(--letter-spacing-laptop, var(--letter-spacing))
      )
    );
    line-height: var(
      --line-height-phone,
      var(--line-height-tablet, var(--line-height-laptop, var(--line-height)))
    );
    text-align: var(
      --text-align-phone,
      var(--text-align-tablet, var(--text-align-laptop, var(--text-align)))
    );
    color: var(
      --color-phone,
      var(--color-tablet, var(--color-laptop, var(--color)))
    );
  }
`;

const MultiLineText = styled(BaseText)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
  overflow: hidden;

  @media ${(p) => p.theme.queries.laptopAndDown} {
    -webkit-line-clamp: var(--max-lines-laptop, var(--max-lines));
  }

  @media ${(p) => p.theme.queries.tabletAndDown} {
    -webkit-line-clamp: var(
      --max-lines-tablet,
      var(--max-lines-laptop, var(--max-lines))
    );
  }

  @media ${(p) => p.theme.queries.phoneAndDown} {
    -webkit-line-clamp: var(
      --max-lines-phone,
      var(--max-lines-tablet, var(--max-lines-laptop, var(--max-lines)))
    );
  }
`;

type HTMLElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';

interface TypeStyles {
  fontSize: string;
  fontWeight: string;
  letterSpacing?: string;
  lineHeight?: string;
}

interface DefaultTypeCSSVariables {
  '--font-size': string;
  '--font-weight': string;
  '--line-height'?: string;
  '--letter-spacing'?: string;
  '--text-transform': string | undefined;
  '--text-decoration': string | undefined;
}

interface LaptopTypeCSSVariables {
  '--font-size-laptop': string | undefined;
  '--font-weight-laptop': string | undefined;
  '--line-height-laptop'?: string | undefined;
  '--letter-spacing-laptop'?: string | undefined;
}

interface TabletTypeCSSVariables {
  '--font-size-tablet': string | undefined;
  '--font-weight-tablet': string | undefined;
  '--line-height-tablet'?: string | undefined;
  '--letter-spacing-tablet'?: string | undefined;
}

interface PhoneTypeCSSVariables {
  '--font-size-phone': string | undefined;
  '--font-weight-phone': string | undefined;
  '--line-height-phone'?: string | undefined;
  '--letter-spacing-phone'?: string | undefined;
}

interface MaxLines {
  default: number;
  laptop?: number;
  tablet?: number;
  phone?: number;
}

type TextAlignValues =
  | 'left'
  | 'right'
  | 'center'
  | 'justify'
  | 'initial'
  | 'inherit';

type TextTransformValues = 'capitalize' | 'uppercase' | 'lowercase' | 'none';

interface TextAlign {
  default: TextAlignValues;
  laptop?: TextAlignValues;
  tablet?: TextAlignValues;
  phone?: TextAlignValues;
}

interface Color {
  default: string;
  laptop?: string;
  tablet?: string;
  phone?: string;
}

export const STYLES: Record<string, TypeStyles> = {
  display01: {
    fontSize: 'var(--font-size-display-01)',
    fontWeight: 'var(--font-weight-semibold)',
    letterSpacing: 'var(--letter-spacing-xl)',
  },
  header01: {
    fontSize: 'var(--font-size-header-01)',
    fontWeight: 'var(--font-weight-medium)',
    letterSpacing: 'var(--letter-spacing-xl)',
  },
  header02: {
    fontSize: 'var(--font-size-header-02)',
    fontWeight: 'var(--font-weight-medium)',
    letterSpacing: 'var(--letter-spacing-lg)',
  },
  header03: {
    fontSize: 'var(--font-size-header-03)',
    fontWeight: 'var(--font-weight-medium)',
    letterSpacing: 'var(--letter-spacing-md)',
  },
  subtitle01: {
    fontSize: 'var(--font-size-subtitle-01)',
    fontWeight: 'var(--font-weight-medium)',
    lineHeight: 'var(--line-height-sm)',
  },
  subtitle01Bold: {
    fontSize: 'var(--font-size-subtitle-01)',
    fontWeight: 'var(--font-weight-semibold)',
    lineHeight: 'var(--line-height-sm)',
  },
  subtitle02: {
    fontSize: 'var(--font-size-subtitle-02)',
    fontWeight: 'var(--font-weight-medium)',
    lineHeight: 'var(--line-height-sm)',
  },
  bodyLarge01: {
    fontSize: 'var(--font-size-body-large-01)',
    fontWeight: 'var(--font-weight-normal)',
    lineHeight: 'var(--line-height-md)',
  },
  bodyLarge01Bold: {
    fontSize: 'var(--font-size-body-large-01)',
    fontWeight: 'var(--font-weight-medium)',
    lineHeight: 'var(--line-height-md)',
  },
  body01: {
    fontSize: 'var(--font-size-body-01)',
    fontWeight: 'var(--font-weight-normal)',
    lineHeight: 'var(--line-height-sm)',
    letterSpacing: 'var(--letter-spacing-sm)',
  },
  body01Bold: {
    fontSize: 'var(--font-size-body-01-bold)',
    fontWeight: 'var(--font-weight-semibold)',
    lineHeight: 'var(--line-height-sm)',
    letterSpacing: 'var(--letter-spacing)',
  },
  body02: {
    fontSize: 'var(--font-size-body-02)',
    fontWeight: 'var(--font-weight-normal)',
    lineHeight: 'var(--line-height-sm)',
    letterSpacing: 'var(--letter-spacing-sm)',
  },
  body02Bold: {
    fontSize: 'var(--font-size-body-02-bold)',
    fontWeight: 'var(--font-weight-semibold)',
    lineHeight: 'var(--line-height-sm)',
    letterSpacing: 'var(--letter-spacing)',
  },
  body03Bold: {
    fontSize: 'var(--font-size-body-03)',
    fontWeight: 'var(--font-weight-semibold)',
    lineHeight: 'var(--line-height-sm)',
    letterSpacing: 'var(--letter-spacing-sm)',
  },
  body03: {
    fontSize: 'var(--font-size-body-03)',
    fontWeight: 'var(--font-weight-normal)',
    lineHeight: 'var(--line-height-sm)',
    letterSpacing: 'var(--letter-spacing-sm)',
  },
};

export enum TypeVariant {
  display01 = 'display01',
  header01 = 'header01',
  header02 = 'header02',
  header03 = 'header03',
  subtitle01 = 'subtitle01',
  subtitle01Bold = 'subtitle01Bold',
  subtitle02 = 'subtitle02',
  bodyLarge01 = 'bodyLarge01',
  bodyLarge01Bold = 'bodyLarge01Bold',
  body01 = 'body01',
  body01Bold = 'body01Bold',
  body02 = 'body02',
  body02Bold = 'body02Bold',
  body03Bold = 'body03Bold',
  body03 = 'body03',
}

interface Props {
  variant: TypeVariant;
  as: HTMLElement;
  color?: string | Color;
  laptop?: TypeVariant;
  tablet?: TypeVariant;
  phone?: TypeVariant;
  maxLines?: number | MaxLines; // this will handle anywhere from 1 to n lines
  textAlign?: TextAlignValues | TextAlign;
  textTransform?: TextTransformValues;
  textDecoration?: string;
  preWrap?: boolean;
  wordBreak?: 'normal' | 'break-word' | 'keep-all' | 'break-all';
  children: ReactNode | ReactNode[];
  dataTest?: string;
}

const Typography = ({
  variant,
  as,
  color,
  laptop,
  tablet,
  phone,
  maxLines,
  textAlign = 'left',
  textTransform = 'none',
  textDecoration = 'none',
  preWrap = false,
  wordBreak = 'break-word',
  children,
  dataTest,
}: Props) => {
  const defaultVariantStyles = STYLES[variant];
  const laptopVariantStyles = laptop ? STYLES[laptop] : undefined;
  const tabletVariantStyles = tablet ? STYLES[tablet] : undefined;
  const phoneVariantStyles = phone ? STYLES[phone] : undefined;

  if (!defaultVariantStyles) {
    throw new Error(`Unrecognized Typography variant: ${variant}`);
  }

  const defaultStyles: DefaultTypeCSSVariables = {
    '--font-size': defaultVariantStyles.fontSize,
    '--font-weight': defaultVariantStyles.fontWeight,
    '--line-height': defaultVariantStyles.lineHeight,
    '--letter-spacing': defaultVariantStyles.letterSpacing,
    '--text-transform': textTransform,
    '--text-decoration': textDecoration,
  };

  const laptopStyles: LaptopTypeCSSVariables = {
    '--font-size-laptop': laptopVariantStyles?.fontSize,
    '--font-weight-laptop': laptopVariantStyles?.fontWeight,
    '--line-height-laptop': laptopVariantStyles?.lineHeight,
    '--letter-spacing-laptop': laptopVariantStyles?.letterSpacing,
  };

  const tabletStyles: TabletTypeCSSVariables = {
    '--font-size-tablet': tabletVariantStyles?.fontSize,
    '--font-weight-tablet': tabletVariantStyles?.fontWeight,
    '--line-height-tablet': tabletVariantStyles?.lineHeight,
    '--letter-spacing-tablet': tabletVariantStyles?.letterSpacing,
  };

  const phoneStyles: PhoneTypeCSSVariables = {
    '--font-size-phone': phoneVariantStyles?.fontSize,
    '--font-weight-phone': phoneVariantStyles?.fontWeight,
    '--line-height-phone': phoneVariantStyles?.lineHeight,
    '--letter-spacing-phone': phoneVariantStyles?.letterSpacing,
  };

  const allStyles = {
    ...defaultStyles,
    ...laptopStyles,
    ...tabletStyles,
    ...phoneStyles,
  };

  let Component;
  if (maxLines) {
    Component = MultiLineText;
  } else {
    Component = BaseText;
  }

  const maxLinesStyles =
    typeof maxLines === 'number'
      ? {
          '--max-lines': maxLines,
        }
      : {
          '--max-lines': maxLines?.default,
          '--max-lines-laptop': maxLines?.laptop,
          '--max-lines-tablet': maxLines?.tablet,
          '--max-lines-phone': maxLines?.phone,
        };

  const textAlignStyles =
    typeof textAlign === 'string'
      ? {
          '--text-align': textAlign,
        }
      : {
          '--text-align': textAlign?.default,
          '--text-align-laptop': textAlign?.laptop,
          '--text-align-tablet': textAlign?.tablet,
          '--text-align-phone': textAlign?.phone,
        };

  const colorStyles =
    typeof color === 'string'
      ? {
          '--color': color,
        }
      : {
          '--color': color?.default,
          '--color-laptop': color?.laptop,
          '--color-tablet': color?.tablet,
          '--color-phone': color?.phone,
        };

  const preWrapStyles = preWrap ? { '--pre-wrap': 'pre-wrap' } : {};
  const wordBreakStyles = { '--word-break': wordBreak };

  return (
    <Component
      style={{
        ...allStyles,
        ...maxLinesStyles,
        ...textAlignStyles,
        ...colorStyles,
        ...preWrapStyles,
        ...wordBreakStyles,
      }}
      data-test={dataTest}
      as={as}
    >
      {children}
    </Component>
  );
};

export default Typography;
